import revive_payload_client_6o5ZOlgBxR from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MvYjziDUdb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6r482BkaFj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lSvSHRyi9A from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_No2kzfO7ry from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mn7Jpp7DCM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NjV1qMZKiJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_wFlpuRrxtJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.0.0__ioredis@5.4._6jezf4xyozibamvaftfn64zsh4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WwqrcUzpY3 from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_M97Mxb9G6V from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.2.11_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16._x4psruiedyfrxuvxwbvprnxpmi/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_6o5ZOlgBxR,
  unhead_MvYjziDUdb,
  router_6r482BkaFj,
  payload_client_lSvSHRyi9A,
  navigation_repaint_client_No2kzfO7ry,
  check_outdated_build_client_mn7Jpp7DCM,
  chunk_reload_client_NjV1qMZKiJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wFlpuRrxtJ,
  plugin_WwqrcUzpY3,
  tooltip_M97Mxb9G6V,
  bugsnag_tkMQfOjcVZ
]